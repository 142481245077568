@keyframes marquee {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-marquee {
  animation: marquee 10s linear infinite;
}
